<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
const defaultLayout = 'BlankLayout'
export default {
  name: "AppLayout",
  computed: {
    layout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`./etc/${layout}.vue`)
    }
  }
}
</script>