import axios from "axios";
axios.defaults.withCredentials = true

export default async function guest({ next, router }) {
    if (localStorage.getItem('token')) {
        await axios.get(`${process.env.VUE_APP_URL_API}/middleware/isAuth`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            if(res.data.isAuth === false) {
                return next();
            } else {
                return router.push({name: 'Home'})
            }
        }).catch((err) => console.error(err))
    } else {
        return next();
    }
}