import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueApexCharts from 'vue-apexcharts'
import VueSplide from '@splidejs/vue-splide';
import VueSweetalert2 from 'vue-sweetalert2';
import VModal from 'vue-js-modal'
import 'sweetalert2/dist/sweetalert2.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import 'vue2-editor/dist/vue2-editor.css'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import { VuejsDatatableFactory } from 'vuejs-datatable';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import Vue2Editor from "vue2-editor";
import moment from 'moment';
// import VueCryptojs from 'vue-cryptojs'
import VueHtml2pdf from 'vue-html2pdf'
import VueClipboard from 'vue-clipboard2'
import vClickOutside from 'v-click-outside'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2'

import firebase from "firebase/app";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: "AIzaSyCwFMymOvhCsT7qqE5Vn20UOmaNZ_GB8GI",
  authDomain: "elingway-5e5b0.firebaseapp.com",
  projectId: "elingway-5e5b0",
  storageBucket: "elingway-5e5b0.appspot.com",
  messagingSenderId: "977113814244",
  appId: "1:977113814244:web:410bd90bccb4b6264ac8f1",
  measurementId: "G-ZVZGVK59RJ"
});

navigator.serviceWorker
// .register("/firebase-messaging-sw.js")
// .then((registration) => {
//   const messaging = firebase.messaging();
//   messaging.useServiceWorker(registration);
// })
// .catch((err) => {
//   console.log(err);
// });

Vue.use(VueSplide);
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2);
Vue.use(VModal);
Vue.use(CKEditor);
Vue.use(Vue2Editor);
// Vue.use(VueCryptojs);
Vue.use(VueHtml2pdf)
Vue.use(VueClipboard)
Vue.use(vClickOutside)

Vue.use(TawkMessengerVue, {
  propertyId : '5f1581d87258dc118bee941e',
  widgetId : '1goga0ctr'
});
// export default function () {
// }

Vue.filter('numFormat', numFormat(numeral));
Vue.filter('formatDateSystem', function(value) {
  if (value) {
      return moment(String(value)).format('YYYY-MM-DD')
  }
});
Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('MMMM, Do YYYY')
  }
});
Vue.filter('formatDateTime', function(value) {
  if (value) {
      return moment(String(value)).format('MMMM, Do YYYY (HH:mm)')
  }
});
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);
const crypt = {
  encrypt: (text) => {
    if (text) {
      var key = Vue.CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
      var iv = Vue.CryptoJS.enc.Hex.parse("101112131415161718191a1b1c1d1e1f");
      return Vue.CryptoJS.Rabbit.encrypt(text.toString(), "123asd123", key, { iv: iv }).toString().replace('+','xMl3Jk').replace('/','Por21Ld').replace('=','Ml32')
    }
  },
  decrypt: (text) => {
    if (text) {
      text = text.toString().replace('xMl3Jk', '+' ).replace('Por21Ld', '/').replace('Ml32', '=')
      return Vue.CryptoJS.Rabbit.decrypt(text, "123asd123").toString(Vue.CryptoJS.enc.Utf8)
    }
  }
}
Vue.prototype.$crypt = crypt
Vue.use(
  VuejsDatatableFactory.useDefaultType( false )
  .registerTableType( 'datatable', tableType => tableType.mergeSettings( {
      table: {
          sorting: {
              sortAsc:  '<i class="fas fa-sort-amount-up" title="Sort ascending"></i>',
              sortDesc: '<i class="fas fa-sort-amount-down" title="Sort descending"></i>',
              sortNone: '<i class="fas fa-sort" title="Sort"></i>',
          },
      },
      pager: {
        classes: {
            pager: 'pagination justify-content-end d-flex mt-4',
            selected: 'bg-current text-white',
            li: 'page-item page-link rounded-lg btn-round-md p-0 fw-600 font-xssss shadow-xss text-current border-1'
        },
        icons: {
            next:     '<i class="fas fa-chevron-right" title="Next page"></i>',
            previous: '<i class="fas fa-chevron-left" title="Previous page"></i>',
        },
      },
  } ) )
  );


Vue.component('apexchart', VueApexCharts)

import AppLayout from './components/layout/AppLayout'

require('./bootstrap');

Vue.component('AppLayout', AppLayout)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
