import axios from "axios";
axios.defaults.withCredentials = true

export default async function verified({ next, router }) {
    let currentName = router.history.current.name
    if (!localStorage.getItem('token') || !localStorage.getItem('user')) {
        if(currentName == 'BrowseNew') {
            return router.push({name: 'Login'}).catch(() => {})
        } else {
            return router.push({name: 'BrowseNew'}).catch(() => {})
        }
    } else {
        const userData = JSON.parse(localStorage.getItem('user'))
        if(userData['email_verified_at'] == null || userData['email_verified_at'] == ""){
            return router.push({name: 'Verification'})
        }
        else {
            return next();
        }
    }
}