window._ = require('lodash');

try {
    window.Popper = require('popper.js').default;
    global.jQuery = require('jquery');
    var $ = global.jQuery;
    window.$ = $;

    require('bootstrap');

} catch (e) {
    console.log(e)
}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true