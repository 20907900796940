import axios from "axios";
axios.defaults.withCredentials = true

export default async function auth({ next, router }) {
    let currentName = router.history.current.name
    if (!localStorage.getItem('token')) {
        if(currentName == 'BrowseNew') {
            return router.push({name: 'Login'}).catch(() => {})
        } else {
            return router.push({name: 'BrowseNew'}).catch(() => {})
        }
    } else {
        await axios.get(`${process.env.VUE_APP_URL_API}/middleware/isAuth`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
            }
        }).then(res => {
            if(res.data.isAuth) {
                return next();
            } else {
                if(currentName == 'BrowseNew') {
                    return router.push({name: 'Login'}).catch(() => {})
                } else {
                    return router.push({name: 'BrowseNew'}).catch(() => {})
                }
            }
        }).catch(() => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if(currentName == 'BrowseNew') {
                return router.push({name: 'Login'}).catch(() => {})
            } else {
                return router.push({name: 'BrowseNew'}).catch(() => {})
            }
        })
    }
}