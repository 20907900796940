<template>
  <AppLayout>
    <div v-show="isLoading" class="preloader"></div>
    <keep-alive><router-view></router-view></keep-alive>
  </AppLayout>
</template>
<!-- <script src="https://app.sandbox.midtrans.com/snap/snap.js" data-client-key="<%= VUE_APP_MIDTRANS_CLIENT_KEY %>"></script> -->
<script src="js/plugin.js"></script>
<script src="vendor/fontawesome/js/all.min.js"></script>
<script src="js/scripts.js"></script>
<script>
import axios from 'axios'
import firebase from 'firebase/app';
import "firebase/messaging";
export default {
  name: 'App',
  data() {
    return {
      layout: `div`,
      isLoading: false
    };
  },
  async created() {
    if(localStorage.getItem('token')){
      await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.token}`
        }
      }).then(res => {
      }).catch((err) => {
        console.error(err)
        if(err.response.status === 401){
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          this.$router.push({name: 'Login'})
        }
      })
    }
  },
  methods: {
  },
}
</script>
